// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "gatsby"
// Hooks
import useTimeout from "../hooks/useTimeout";
// Components
import Cursor from "../components/Cursor";
import Header from "../components/Header";
import Information from "../components/Information";
import Splash from "../components/Splash";
import Meta from "../components/Meta";
import ProjectBrowser from "../components/project/ProjectBrowser";
import ProjectBrowserStore from "../components/project/ProjectBrowserStore";
// CSS
import "../css/app.scss";

// const Message = () => {
//   const [show, setShow] = useState(false)
//
//   useTimeout(() => {
//     setShow(true)
//   }, 2000, { persistRenders: true })
//
//   return (
//     <div className="pfix b0 r0 padx2" style={{ color: show ? 'blue' : 'red', zIndex: 1000 }}>{show ? 'Mounted' : 'Mounting'}</div>
//   )
// }

const Layout = (props) => {
  const { pageContext, children, cursorColor } = props
  const { title, slug, description, image, isInformation = false } = pageContext

  return (
    <ProjectBrowserStore>
      <main>
        <Meta title={title} description={description} shareImage={image} />
        <Splash />
        <Header projectSlug={slug} isInformation={isInformation} />
        <ProjectBrowser slug={slug} />
        <Information show={isInformation} />
        {children}
        <Cursor color={isInformation ? '#EFECE6' : null} />
      </main>
    </ProjectBrowserStore>
  )
}

export default Layout
