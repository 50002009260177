// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// Hooks
import useSettings from "../hooks/useSettings"

function Meta({ description, lang, meta, title, shareImage }) {

  const { defaultTitle, defaultDescription, defaultShareImage } = useSettings();

  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaImage = shareImage || defaultShareImage.fluid.src;
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s — ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.string
};

export default Meta;
