// Dependencies
import React, { useState, useEffect, useCallback, useRef, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby";
import useTouch from "../../hooks/useTouch";
// Components
// ...
// Sub-components
import ProjectsIndex from "./ProjectsIndex";
import Project from "./Project";

function useSelectedProjects() {
  const data = useStaticQuery(graphql`
    {
      allContentfulWork {
        nodes {
          selectedProjects {
            ...ContentfulProjectFragment
          }
        }
      }
    }
  `)
  return data.allContentfulWork.nodes[0].selectedProjects
}

/*
// --------------------------------
// <ProjectBrowser />

- Receives all projects
- Will get current project based on page, or based on click through from index
- Needs to virtually update router history as we click around
*/
const ProjectBrowser = ({ slug }) => {

  // Data
  const projects = useSelectedProjects();
  const currentProject = slug && projects.find(entry => entry.slug === slug);
  // State
  const container = useRef();
  const [gridView] = useState(false); // setGridView
  const [activeIndex, setActiveIndex] = useState(0);

  const [isTouch, canRender] = useTouch();

  // Sort by project...
  const updateCurrentProject = useCallback(() => {
    if (slug) {
      const thisProjectIndex = projects.findIndex(entry => entry.slug === slug);
      setActiveIndex(thisProjectIndex);
      container.current.scrollTop = window.innerHeight * thisProjectIndex;
    }
  }, [slug, projects, container, setActiveIndex]);
  useEffect(updateCurrentProject, [slug]);

  return (
    <div id="projects">
      <div
        className={`
          selected-projects
          ${gridView ? 'grid-view' : 'list-view'}
          ${currentProject ? 'view-project' : ''}
          ${isTouch ? 'is-touch' : ''}
        `}
        ref={container}
      >
        {projects.map((node, index) => {
          return (
            <Project
              slug={node.slug}
              index={index}
              images={node.images}
              currentProject={currentProject}
              highlightProject={setActiveIndex}
              isGridViewActive={gridView}
              key={`project-${node.slug}-section-${index}`}
            />
          )
        })}
      </div>

      {!currentProject && (
        <ProjectsIndex
          entries={projects}
          activeIndex={activeIndex}
          scrollContainer={container}
        />
      )}

    </div>
  )
}

export default ProjectBrowser


/*
// --------------------------------
// ContentfulProjectFragment

- Project content fragment
- eg:

graphql`
 {
   allContentfulWork {
     nodes {
       selectedProjects {
         ...ContentfulProjectFragment
       }
     }
   }
 }
`
*/

export const contentfulProjectFragment = graphql`
  fragment ContentfulProjectFragment on ContentfulProject {
    title
    slug
    year
    # Images (gallery)
    images {
      fluid(maxHeight: 1080) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    # Thumbnails
    thumbnails: images {
      fluid(maxHeight: 300) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    # Details
    details {
      childMarkdownRemark {
        html
      }
    }
    # Description
    description {
      childMarkdownRemark {
        html
      }
    }
  }
`
