import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle: title
          defaultDescription: description
          defaultShareImage: shareImage {
            fluid(maxHeight: 640) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          splashImages {
            fluid(maxHeight: 1080) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
