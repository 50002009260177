// Dependencies
import React, { useState } from "react";
// Hooks
import useSettings from "../hooks/useSettings";
import useRandomItem from "../hooks/useRandomItem";
import useTimeout from "../hooks/useTimeout";
import useSessionStorage from "../hooks/useSessionStorage";
// Components
import Image from "../components/Image";

const Splash = () => {

  // Content
  const { splashImages } = useSettings();
  const image = useRandomItem(splashImages);
  // UI
  const [hidden, setHidden] = useSessionStorage('hideSplash', false); // false
  const [fadeOut, setFadeOut] = useState(false);

  useTimeout(() => {
    setFadeOut(true)
  }, 3000, { persistRenders: true });

  const handleAnimationEnd = () => {
    setHidden(true)
  }

  return (
    !hidden && (
      <div className={`splash fs pfix ${fadeOut ? 'hide' : ''}`} onAnimationEnd={handleAnimationEnd}>
        <Image {...image} />
        <div className="text split pabs fs">
          <span>Adam Jordan</span>
          <span>Architecture</span>
        </div>
        <div className="text full pabs fs align-center">
          <span>Adam Jordan Architecture</span>
        </div>
      </div>
    )
  )
}

export default Splash
