// Dependencies
import React, { useContext } from "react";

const Loader = () => (
  <div className="loader">
    <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.00001 2.53589C2.08684 3.64046 1.43134 6.08682 2.53591 7.99999L3.40193 7.49999C2.57351 6.06512 3.06513 4.23035 4.50001 3.40192C5.93489 2.57349 7.76966 3.06512 8.59809 4.49999C9.42651 5.93487 8.93489 7.76964 7.50001 8.59807C6.65877 9.08376 5.68008 9.11572 4.84732 8.76987L4.46383 9.69347C5.57401 10.1543 6.87861 10.1115 8.00001 9.4641C9.91318 8.35953 10.5687 5.91316 9.46411 3.99999C8.35954 2.08682 5.91318 1.43132 4.00001 2.53589Z" fill="#B1ACA8"/>
    </svg>
  </div>
)

export default Loader
