/* eslint-disable__turnoff */
// Dependencies
import React, { useEffect, useContext, useRef } from "react"
import { useInView } from "react-intersection-observer";
import smoothscroll from 'smoothscroll-polyfill';
// Hooks
import useTouch from "../../hooks/useTouch";
// Components
import Image from "../../components/Image";
import Markdown from "../../components/Markdown";
// Sub-components
import { ProjectCursorActionsContext } from "./ProjectBrowserStore";

/*
// --------------------------------
// ProjectGallery, ProjectSlide - slideshow component that scrolls between slides
*/

const ProjectGallery = ({ galleryRef, projectSlug, images, currentProject, isCurrentProject, trackIndex, setShowInfo }) => {

  const slidesRef = useRef(images.map(() => React.createRef()));
  const [currentSlideIndex, setCurrentSlideIndex] = trackIndex;
  const [isTouch] = useTouch();

  const scrollIntoView = (element) => {
    if (element) {
      element.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        inline: "nearest",
      })
    }
  }

  const handleGalleryClick = (event) => {
    if (isCurrentProject) {
      if (event.clientX < (window.innerWidth * 0.5)) {
        scrollIntoView(slidesRef.current[currentSlideIndex - 1]?.current)
      } else if (event.clientX >= (window.innerWidth * 0.5)) {
        const nextSlide = slidesRef.current[currentSlideIndex + 1]?.current
        scrollIntoView(nextSlide)
        if (!nextSlide) {
          setShowInfo(true)
        }
      }
    }
  }

  // I wonder if this is the best pattern...
  const setCursorStyle = useContext(ProjectCursorActionsContext);
  let cursorPosition = false;
  const handleMouseMove = (event) => {
    if ( (event.clientX < (window.innerWidth * 0.5)) && (cursorPosition !== 'larr') ) {
      cursorPosition = 'larr';
      setCursorStyle('larr');
    } else if ( (event.clientX >= (window.innerWidth * 0.5)) && (cursorPosition !== 'rarr') ) {
      cursorPosition = 'rarr';
      setCursorStyle('rarr');
    }
  }

  const handleMouseLeave = () => {
    cursorPosition = false;
    setCursorStyle('auto');
  }

  const handlers = (isCurrentProject && !isTouch) ? {
    onClick: handleGalleryClick,
    onMouseMove: handleMouseMove,
    onMouseLeave: handleMouseLeave
  } : {}

  return (
    <div className="project-gallery fs prel" ref={galleryRef} {...handlers}>
      {images.map((asset, index) => (
        <ProjectSlide
          asset={asset}
          currentProject={currentProject}
          isCurrentProject={isCurrentProject}
          slideIndex={index}
          isActiveSlide={currentSlideIndex === index}
          setCurrentSlideIndex={setCurrentSlideIndex}
          slideRef={slidesRef.current[index]}
          key={`${projectSlug}-slide-${index}`}
        />
      ))}
    </div>
  )
}

const ProjectSlide = ({ currentProject, isCurrentProject, asset, slideIndex, isActiveSlide, setCurrentSlideIndex, slideRef }) => {

  const [ref, inView, entry] = useInView({
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      setCurrentSlideIndex(slideIndex)
    }
  }, [inView])

  return (
    <section className={`fs ${!isCurrentProject && !isActiveSlide ? 'hidden' : ''}`} ref={slideRef}>
      <div className="slide-image pabs fs" style={{ transform: currentProject ? `scale(1)` : `scale(0.8)` }} ref={ref}>
        <Image {...asset} />
      </div>
    </section>
  )
}


export default ProjectGallery
